import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavBar from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import {
  Title,
  SubTitle,
  Button,
  Span,
  Textarea,
} from "../../../StyleComponents/styles"

const navRoadArray = [
  { name: "Utility Tools", link: "/utility-tools/" },
  {
    name: "JSON to XML",
    link: "/json-to-xml/",
  },
]
const seeAlsoArray = [
  "/csv-to-json-converter",
  "/csv-to-json-converter",
  "/csv-to-tabel",
  "/csv-to-sql-conversion",
  "/json-to-csv-conversion",
  "/text-to-csv-conversion",
]

function JsonToXml(props) {
  let [jsonTexarea, setjsonTexarea] = useState("")
  let [xmlTextarea, setxmlTextarea] = useState("")

  function jsonTexareaC(e) {
    setjsonTexarea(e.target.value)
  }

  function converter() {
    var options = { compact: true, ignoreComment: true, spaces: 4 }
    let json = jsonTexarea

    if (json instanceof Buffer) {
      json = json.toString()
    }
    import("xml-js").then(e => {
      let convert = e.default
      var result = convert.json2xml(json, options)
      setxmlTextarea(result)
    })
  }
  return (
    <Layout location={props.location}>
      <SEO
        title="Json to Xml - convert json to xml online tools"
        description="JSON to XML converter online. Just input your JSON format in the input above and click the conversion button to convert JSON to XML."
        keywords={[
          "json to xml, convert json to xml, json xml convertion json to xml converter .",
        ]}
      />
      <NavBar listPages={navRoadArray} />
      <div className="container">
        <Title>Convert JSON to XML</Title>
        <SubTitle>JSON to XML</SubTitle>

        <Span>Paste Your Json Here:</Span>
        <Textarea value={jsonTexarea} onChange={jsonTexareaC}></Textarea>
        <Button
          borderColor="#1da1f2"
          color="#1da1f2"
          hoverColor="white"
          hoverBorderColor="#1da1f2"
          hoverBackgroundColor="#1da1f2"
          onClick={converter}
          className="mb-3"
        >
          Convert To Xml
        </Button>

        <Textarea value={xmlTextarea} readOnly></Textarea>

        <br />
        <h3>JSON to XML</h3>
        <p>
          Just input your JSON format in the input above and click the
          conversion button to convert JSON to XML.
        </p>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
        <br />
      </div>
    </Layout>
  )
}

export default JsonToXml
